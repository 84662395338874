import React from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'
import Stage from '@components/stage'
import ToC from '@components/toc'
import { createTocList } from '@tools/componentParser'
import ContentfulComponentArray from '@tools/ContentfulComponentArray'
import Container from '@objects/container'
import Card from '@objects/card'
import Copy from '@objects/copy'
import Button from '@objects/button'
import Headline from '@objects/headline'

import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  downloadContainer: {
    marginTop: theme.spacing(12),
    textAlign: 'right',
  },
  downloadHeadline: {
    margin: `${0}!important`,
    ...theme.typography.label,
  },
  downloadItem: {
    display: 'block',
    marginTop: theme.spacing(4),
    paddingLeft: '50%',
  },
}))

function FuerKommunen({ pageContext, data }) {
  const intl = useIntl()
  const classes = useStyles()
  const articleintro =
    data.contentfulArticle?.introCopy?.childMarkdownRemark?.html
  const articlecontent = data.contentfulArticle?.copy?.json?.content

  let tocItems = createTocList(articlecontent, pageContext.locale)

  if (!tocItems) {
    tocItems = []
  }
  const pdf = data.contentfulArticle?.pdf

  return (
    <>
      <Stage
        alignTop={true}
        type={'generic'}
        title={pageContext.page.title}
        ariaLabel={pageContext.page.title}
      />
      <ToC items={tocItems}>
        {articleintro && (
          <Container
            id={tocItems[0].id}
            sidebar
            ariaLabel="arialabel.articleintro"
          >
            <Copy type="intro">{articleintro}</Copy>
          </Container>
        )}
        {articlecontent && (
          <ContentfulComponentArray componentarray={articlecontent} toc />
        )}
        {!!pdf && (
          <Container
            className={classes.downloadContainer}
            type="article"
            width="lg"
            ariaLabel="arialabel.downloads"
          >
            <Headline className={classes.downloadHeadline} level={3}>
              <FormattedMessage id="article.downloads" />
            </Headline>
            {!!pdf && (
              <Button
                className={classes.downloadItem}
                type="text"
                icon="Download"
                href={pdf.file.url}
              >
                {pdf.title} (PDF,{' '}
                {(pdf.file?.details.size / (1024 * 1024)).toFixed(2)}MB)
              </Button>
            )}
          </Container>
        )}
      </ToC>
    </>
  )
}

FuerKommunen.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default FuerKommunen

export const pageQuery = graphql`
  query FuerKommunenQuery($locale: String!) {
    contentfulArticle(
      node_locale: { eq: $locale }
      slug: { eq: "fuer-kommunen" }
    ) {
      id
      introCopy {
        childMarkdownRemark {
          html
        }
      }
      copy {
        json
      }
      pdf {
        title
        file {
          url
          details {
            size
          }
        }
      }
    }

    contactText: contentfulStaticField(key: { eq: "contact.teaser.text" }) {
      value {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`
